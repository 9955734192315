<template>
    <div>
        <b-card title="Gestion des codes postaux">
            <b-col cols="3">
                <b-form-group label="Sélectionner une marque" label-for="input-select-brand">
                    <b-form-select id="input-select-brand" v-model="selectedBrand" :options="brands" @change="fetchBrands"></b-form-select>
                </b-form-group>
            </b-col>

            <pos-table :brand="selectedBrand" :posInfo="posInfo" :subByPass="subByPass"></pos-table>
        </b-card>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import PosTable from './pos-table.vue';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            PosTable,
        },
        data() {
            return {
                brands: [],
                posInfo: [],
                selectedBrand: 'citroen',
                subByPass: false,
                marque: '',
            };
        },
        mounted() {
            this.init();
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        methods: {
            ...mapActions({
                getBrands: 'app/getBrands',
                getInfoPos: 'app/getInfoPos',
                getPosOfInterest: 'app/getPosOfInterest',
                getConfigParams: 'app/getConfigParams',
            }),
            init() {
                this.$root.toggleGlobalLoading(false);
                let promises = [this.fetchBrands()];
                Promise.all(promises)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            fetchBrands() {
                this.$set(this, 'marque', this.selectedBrand);
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                    this.fetchPosInfo();
                });
            },
            async fetchPosInfo() {
                let posInfo = [];
                return this.getPosOfInterest(this.selectedBrand).then((res) => {
                    this.getInfoPos(this.selectedBrand)
                        .then((response) => {
                            for (let item of res.data) {
                                for (let info of response.data) {
                                    if (item.point_code === info.point_code) {
                                        let customerId = { ...item, customer_id: info.customer_id };
                                        posInfo.push(customerId);
                                    }
                                }
                            }
                        })
                        .then(() => {
                            this.$set(this, 'posInfo', posInfo);
                        });
                });
            },
        },
    };
</script>

<style lang="scss"></style>
